import { useEffect, useState } from "react";
import AuthAction from "../../Actions/auth.service";
import ConnectionCard from "../../Components/ConnectionCard";
import { hideLoader, showLoader } from "../../utils/helpers";

const Connections = () => {
  const [connections, setConnections] = useState([]);

  useEffect(() => {
    getConnections();
  }, []);

  const getConnections = () => {
    showLoader();
    AuthAction.getConnections()
      .then((res) => {
        setConnections(res);
        hideLoader();
      })
      .catch((err) => {
        hideLoader();
        console.log(err, "Error while fetching Connections list");
      });
  };

  return (
    <>
      <h4 className="my-connection-h4">My Connections</h4>
      <div className="connections-div text-center">
        {connections.length > 0 ? (
          <ConnectionCard
            connections={connections}
            getConnections={getConnections}
          />
        ) : (
          <h5>No Connections Found</h5>
        )}
      </div>
    </>
  );
};

export default Connections;
