const settings = {
  TEST_EASEBUZZ_HASH: "2PBP7IABZ2",
  EASEBUZZ_HASH: "99WHAHXBEH",
  // BACKEND_URL: "https://api.wegroup4.com",
  // BACKEND_URL: "http://localhost:3010",
  BACKEND_URL: "https://dev-api.wegroup4.com",
  // BACKEND_URL: "https://wegroup.dev.seraphic.io/api",
  // BACKEND_URL: "http://ec2-65-0-181-113.ap-south-1.compute.amazonaws.com:3010",
  // BACKEND_URL: "http://13.58.164.175:4040",
  DEMO: "false",
};

export default settings;
