import React, { useState, useContext, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { AuthContext } from "../../Context/AuthContext";
import AuthAction from "../../Actions/auth.service";
import {
  haveValue,
  isValidDate,
  validateJOIFormField,
  validateJOIProperty,
} from "./../../utils/helpers";
import * as Joi from "joi-browser";
import FieldError from "./../../../src/Components/Common/FieldError";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import axios from "axios";
import { ShowImage } from "./../../utils/helpers";
import { showLoader, hideLoader } from "./../../utils/helpers";
import { ThemeContext } from "../../hooks/useThemeContext";
import moment from "moment";
import { toast } from "react-toastify";
import imageCompression from "browser-image-compression";
import { auth } from "../../firbase";
import {
  createUserWithEmailAndPassword,
  fetchSignInMethodsForEmail,
  sendEmailVerification,
  signInWithEmailAndPassword,
} from "firebase/auth";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";

const EditProfile = () => {
  const navigate = useNavigate();
  const {
    isLoggedIn,
    setIsLoggedIn,
    setIsSidebarVisible,
    setLoggedInUserData,
  } = useContext(AuthContext);
  const [myUserInfo, setMyUserInfo] = useState([]);
  const [currentUserInfo, setCurrentUserInfo] = useState([]);
  const [errors, setErrors] = useState({});
  const [countryCode, setCountryCode] = useState("us");
  const [imageUpdate, setImageUpdate] = useState();
  const [startDate, setStartDate] = useState(new Date());

  const { showErrorToast, showSuccessToast } = useContext(ThemeContext);

  const schema = {
    fullName: Joi.string().required().label("Name"),
    email: Joi.string().email().required().label("Email"),
    phoneNumber: Joi.string().required().label("Phone Number"),
    dob: Joi.string().label("Date Of Birth"),
    anniversary: Joi.string().label("Anniversary"),
  };

  const getLocation = async () => {
    if (!haveValue(myUserInfo?.phoneNumber)) {
      axios({ url: `https://ipapi.co/json/` })
        .then((res) => {
          setCountryCode(res?.data?.country_code.toString().toLowerCase());
        })
        .catch((err) => {});
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      showLoader();
      AuthAction.getUserData()
        .then((res) => {
          if (!haveValue(res?.phoneNumber)) getLocation();
          setMyUserInfo(res);
          setCurrentUserInfo(res);
          hideLoader();
        })
        .catch((err) => {
          console.log(err);
          hideLoader();
          if (err?.response?.data?.message == "Unauthorized User.") {
            localStorage.removeItem("token");
            setIsLoggedIn(false);
            setIsSidebarVisible(false);

            navigate("/");
          }
        });
    }

    return () => {
      console.log(myUserInfo?.phoneNumber);
      // getLocation();
    };
  }, []);

  const handleForm = async (e, name) => {
    let fieldName = name;
    let fieldValue = e;
    let errorData = { ...errors };
    const errorMessage = validateJOIProperty(schema, fieldName, fieldValue);
    if (errorMessage) {
      errorData[fieldName] = errorMessage;
    } else {
      delete errorData[fieldName];
    }
    setErrors(errorData);
    if (e instanceof File && e.type.startsWith("image/")) {
      const imageFile = e;
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
      };
      try {
        showLoader();
        const compressedFile = await imageCompression(imageFile, options);
        var convertBlobToFile = await new File(
          [compressedFile],
          compressedFile.name,
          { type: "image/jpeg" }
        );
        fieldValue = convertBlobToFile;
        setMyUserInfo((existingValue) => ({
          ...existingValue,
          [fieldName]: fieldValue,
        }));
        hideLoader();
      } catch (error) {
        console.log(error);
      }
    } else {
      setMyUserInfo((existingValue) => ({
        ...existingValue,
        [fieldName]: fieldValue,
      }));
    }

    if (name === "file") {
      setImageUpdate(fieldValue);
    }
  };

  const verifyEmail = async () => {
    createUserWithEmailAndPassword(auth, myUserInfo?.email, "password")
      .then((userCredential) => {
        // send verification mail.
        sendEmailVerification(auth.currentUser)
          .then((res) => {
            // Email verification sent!
            auth.signOut();
          })
          .catch(alert);
      })
      .catch((err) => {
        console.log({ err }, "err");
      });
  };

  const resendEmail = async () => {
    signInWithEmailAndPassword(auth, myUserInfo?.email, "password").then(
      (userCredential) => {
        sendEmailVerification(auth.currentUser)
          .then((res) => {
            auth.signOut();
          })
          .catch(alert);
        auth.signOut();
      }
    );
  };

  const updateDetails = () => {
    let errors = validateJOIFormField(myUserInfo, schema);
    if (errors == null) {
      // return false;
      const data = new FormData();
      data.append("fullName", myUserInfo.fullName);
      data.append("email", myUserInfo.email);
      if (haveValue(myUserInfo.phoneNumber)) {
        if (myUserInfo.phoneNumber.startsWith("+")) {
          data.append("phoneNumber", myUserInfo.phoneNumber);
        } else {
          data.append("phoneNumber", "+" + myUserInfo.phoneNumber);
        }
      }
      if (haveValue(myUserInfo.dob)) {
        data.append("dob", myUserInfo.dob);
      }
      data.append("anniversary", myUserInfo.anniversary);
      // data.append("file",)
      if (myUserInfo.file) {
        data.append("file", myUserInfo.file);
      }
      showLoader();
      AuthAction.updateProfile(data)
        .then((res) => {
          if (
            currentUserInfo?.email != myUserInfo?.email &&
            haveValue(myUserInfo?.email)
          ) {
            fetchSignInMethodsForEmail(auth, myUserInfo?.email)
              .then(function (signInMethods) {
                if (signInMethods.length > 0) {
                  console.log("User exists");
                  resendEmail();
                } else {
                  console.log("User does not exist");
                  verifyEmail();
                }
              })
              .catch(function (error) {
                console.log("Error checking user:", error);
              });
            showSuccessToast("Email Verification Mail Sent.");
          } else {
            showSuccessToast("User Details Updated");
          }
          hideLoader();
          setLoggedInUserData(res);
          // console.log("ADTER AKLSDJALSD",res)
          setTimeout(() => {
            navigate("/getStarted");
          }, 3000);
        })
        .catch((err) => {
          hideLoader();
          console.log(err);
          showErrorToast(err.response.data.message);
          if (err?.response?.data?.message == "Unauthorized User.") {
            localStorage.removeItem("token");
            toast.dismiss();
            setIsLoggedIn(false);
            setIsSidebarVisible(false);

            navigate("/");
          }
          console.log(err);
        });
    } else {
      setErrors(errors);
    }
  };
  console.log(myUserInfo?.phoneNumber);

  return (
    <>
      <div className="profile-tophead">
        <h4 className="titleevent">Edit Profile</h4>
        <div className="uploadmedia imageuploaded">
          {imageUpdate ? (
            <>
              {" "}
              <div className="avatar-img">
                <input
                  type="file"
                  accept="image/*"
                  className="uploadinput"
                  onChange={(e) => handleForm(e.target.files[0], "file")}
                />
                <img src={URL.createObjectURL(imageUpdate)} alt="img" />
              </div>
            </>
          ) : (
            <>
              {" "}
              <div className="avatar-img">
                <input
                  type="file"
                  accept="image/*"
                  className="uploadinput"
                  onChange={(e) => handleForm(e.target.files[0], "file")}
                />
                <img
                  src={ShowImage(
                    myUserInfo?.profilePhoto,
                    myUserInfo?.fullName
                  )}
                  alt="img"
                />
              </div>
            </>
          )}
        </div>
        <div className="userinfo-bar">
          <h4>{myUserInfo?.fullName}</h4>
        </div>
        <Form>
          <Form.Group className="formgroup" controlId="formBasicEmail">
            <Form.Label>Name</Form.Label>
            <Form.Control
              name="fullName"
              type="text"
              placeholder="Enter your name"
              onChange={(e) => {
                const regex = /^[a-zA-Z0-9 ]*$/;
                const inputValue = e.target.value;
                if (regex.test(inputValue)) {
                  handleForm(e?.target.value, "fullName");
                }
              }}
              value={myUserInfo?.fullName}
            />
            <FieldError error={errors.fullName} />
          </Form.Group>
          <Form.Group className="formgroup" controlId="formBasicEmail">
            <Form.Label>Email ID</Form.Label>
            <Form.Control
              name="email"
              type="email"
              placeholder="Enter your email"
              value={myUserInfo?.email}
              onChange={(e) => handleForm(e?.target.value, "email")}
            />
            <FieldError error={errors.email} />
          </Form.Group>
          <Form.Group className="formgroup" controlId="formBasicEmail">
            <Form.Label>Mobile Number</Form.Label>
            <PhoneInput
              className={errors.phoneNumber ? "error" : ""}
              country={countryCode}
              onChange={(e) => handleForm(e, "phoneNumber")}
              value={myUserInfo?.phoneNumber}
              placeholder="Enter your mobile number"
            />
            <FieldError error={errors.phoneNumber} />
          </Form.Group>
          <Form.Group
            className="formgroup w-100 z-index-1"
            controlId="formBasicEmail"
          >
            <Form.Label>Date of Birth</Form.Label>
            {/* <input className='datepickerbar' type='date' placeholder="Date" onChange={(e) => handleForm(e.target.value, "dob")} max={moment().format("YYYY-MM-DD")} value={myUserInfo?.dob} /> */}
            <DatePicker
              selected={
                haveValue(myUserInfo?.dob) ? new Date(myUserInfo?.dob) : ""
              }
              //   onSelect={(date) => setStartDate(date)}
              onChange={(date) => {
                handleForm(date.toISOString(), "dob");
              }}
              value={new Date(myUserInfo?.dob)}
              className="datepickerbar"
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              maxDate={new Date()}
            />
          </Form.Group>
          <Form.Group
            className="formgroup w-100 z-index-1"
            controlId="formBasicEmail"
          >
            <Form.Label>Anniversary.</Form.Label>
            {/* <DatePicker value={myUserInfo?.anniversary} onChange={(date) => handleForm(date, 'anniversary')} /> */}
            <DatePicker
              selected={
                haveValue(myUserInfo?.anniversary)
                  ? new Date(myUserInfo?.anniversary)
                  : ""
              }
              //   onSelect={(date) => setStartDate(date)}
              onChange={(date) => {
                handleForm(date.toISOString(), "anniversary");
              }}
              value={new Date(myUserInfo?.anniversary)}
              className="datepickerbar"
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />

            {/* <input className='datepickerbar' type='date' placeholder="Date" onChange={(e) => handleForm(e.target.value, "anniversary")} value={myUserInfo?.anniversary} /> */}
            <FieldError error={errors.anniversary} />
          </Form.Group>
        </Form>
      </div>
      <div className="eventbar mt-5">
        <Button className="btn-pink mt-3" onClick={updateDetails}>
          Update
        </Button>
      </div>
    </>
  );
};

export default EditProfile;
