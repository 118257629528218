import { useEffect, useState } from "react";
import AuthAction from "../../Actions/auth.service";
import NotificationCard from "../../Components/Notification/NotificationCard";
import { hideLoader, showLoader } from "../../utils/helpers";

const Notification = () => {
  const [timePeriod, setTimePeriod] = useState("Today");
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const selectedDate = new Date();
    showLoader();
    AuthAction.getConnectionsDates({ date: selectedDate, period: timePeriod })
      .then((res) => {
        setEvents(res);
        hideLoader();
      })
      .catch((err) => {
        console.log(err, "Error in fetching Connections Dates");
        hideLoader();
      });
  }, [timePeriod]);

  return (
    <>
      <h4 className="my-connection-h4">Notifications</h4>
      <div>
        <div className="notification-tabs">
          <div
            onClick={(e) => setTimePeriod("Today")}
            className={`time-period-div cursor-pointer ${
              timePeriod == "Today" && "selected"
            }`}
          >
            <h5>Today</h5>
          </div>
          <div
            onClick={(e) => setTimePeriod("Tomorrow")}
            className={`time-period-div cursor-pointer ${
              timePeriod == "Tomorrow" && "selected"
            }`}
          >
            <h5>Tomorrow</h5>
          </div>
          <div
            onClick={(e) => setTimePeriod("ThisWeek")}
            className={`time-period-div cursor-pointer ${
              timePeriod == "ThisWeek" && "selected"
            }`}
          >
            <h5>This Week</h5>
          </div>
        </div>
      </div>
      {events.length > 0
        ? events.map((event) => <NotificationCard event={event} />)
        : "No Event"}
    </>
  );
};

export default Notification;
